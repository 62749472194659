<template>
    <div class="row vh-100">
        <img
            src="/img/omron-logo-og-1200px.png"
            height="200px"
            width="200px"
            alt="Omron Logo"
            class="logo my-auto mx-auto rounded"
        />
    </div>
</template>
<script>
import { onMounted } from "@vue/composition-api";
import store from "@/store";
import router from "@/router";
export default {
    setup(props, context) {
        onMounted(() => {
            console.log(store.getters["auth/user"]);
            if (store.getters["auth/user"].tipo == 2) {
                setTimeout(() => {
                    router.push({ name: "dashboard-admin" });
                }, 3000);
            }

            if (store.getters["auth/user"].tipo == 4) {
                setTimeout(() => {
                    router.push({ name: "dashboard-profesional" });
                }, 3000);
            }
        });
    },
};
</script>
<style lang="css" scope>
.logo {
    animation: logo-animation 3s;
}

@keyframes logo-animation {
    0% {
        opacity: 0;
        transform: rotate3d(2, 1, 1, 258deg);
        box-shadow: -1px 26px 20px 2px rgb(0 0 0 / 38%);
    }
    50% {
        opacity: 1;
        box-shadow: -1px 26px 20px 2px rgb(0 0 0 / 38%);
        transform: rotate3d(2, 1, 1, 360deg);
    }
    95% {
        box-shadow: -1px 26px 20px 2px rgb(0 0 0 / 0%);
    }
}
</style>
